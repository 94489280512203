import * as React from "react"
import App from "./app"
import './common.scss'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-datetime/css/react-datetime.css'
import 'material-icons/iconfont/material-icons.css'
import 'react-toastify/dist/ReactToastify.css'
import 'react-multi-carousel/lib/styles.css'

const IndexPage = () => {
  return <App />
}

// export const Head = () => <title>Home Page</title>

export default IndexPage


